<template>
  <v-list>
    <v-list-item v-if="!minVariant" two-line>
      <v-list-item-avatar>
        <v-avatar color="primary" size="24" v-text="_.head(name)" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-title v-bind="attrs" v-on="on" v-text="name" />
          </template>
          <span>{{ name }}</span>
        </v-tooltip>
        <v-list-item-subtitle v-if="role.name" v-text="role.name" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item :to="{ hash: 'UserEditDialog' }">
      <v-list-item-icon>
        <v-icon v-text="'mdi-account'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Профиль'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="userParams && userParams.navigation && userParams.navigation.UserListPage" :to="{name: 'UserListPage'}">
      <v-list-item-icon>
        <v-icon v-text="'mdi-account-group'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Сотрудники'" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="signOut">
      <v-list-item-icon>
        <v-icon v-text="'mdi-logout-variant'" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="'Выйти'" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UserBar',
  props: {
    minVariant: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      'name': 'user/name',
      'role': 'user/role',
      'userParams': 'user/userParams',
    }),
  },
  methods: {
    ...mapActions({
      fetch: 'user/fetch',
      signOut: 'user/signOut',
    }),
  },
  beforeMount() {
    this.fetch();
  }
}
</script>

<style module lang="scss">
.name{
  position: relative;
}
</style>
